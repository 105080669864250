import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'students',
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/Calendar/Calendar.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // DASHBOARD //
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/pages/Dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // STUDENTS //
  {
    path: '/students',
    name: 'students',
    component: () => import('@/views/pages/Students/Students.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/student/new',
    name: 'student-new',
    component: () => import('@/views/pages/Students/StudentNew.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/student/edit/:id',
    name: 'student-edit',
    component: () => import('@/views/pages/Students/StudentEdit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/student/view/:id',
    name: 'student-single',
    component: () => import('@/views/pages/Students/StudentSingle.vue'),
    meta: {
      requiresAuth: true,
      requiresStore: ['getInfos'],
    },
  },

  // END STUDENTS //
  // CREIPEF //
  {
    path: '/student/creipef/:id',
    name: 'student-creipef',
    component: () => import('@/views/pages/Creipef/CreipefForm.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // END CREIPEF //

  // FORMATIONS //
  {
    path: '/formations',
    name: 'formations',
    component: () => import('@/views/pages/Formations/Formations.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/formation/edit/:id',
    name: 'formation-edit',
    component: () => import('@/views/pages/Formations/FormationEdit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/formation/create',
    name: 'formation-create',
    component: () => import('@/views/pages/Formations/FormationCreate.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // END FORMATIONS //
  // CERTIFICATIONS //
  {
    path: '/certifications',
    name: 'certifications',
    component: () => import('@/views/pages/Certifications/Certifications.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/certification/edit/:id',
    name: 'certification-edit',
    component: () => import('@/views/pages/Certifications/CertificationEdit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/certification/create',
    name: 'certification-create',
    component: () => import('@/views/pages/Certifications/CertificationCreate.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // END CERTIFICATIONS //
  // REVIEWS //
  {
    path: '/public/satisfaction-instant/',
    name: 'reviews-hot',
    component: () => import('@/views/pages/Reviews/ReviewFormHot.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reviews/',
    name: 'reviews',
    component: () => import('@/views/pages/Reviews/Reviews.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // END REVIEWS //
  // TUTORAT //
  {
    path: '/tutorat/',
    name: 'tutorat',
    component: () => import('@/views/pages/Tutorat/TutoratDatatable.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tutorat-planning/',
    name: 'tutorat-planning',
    component: () => import('@/views/pages/Tutorat/TutoratPlanning.vue'),
    meta: {
      requiresTuteur: true,
    },
  },

  // END TUTORAT //
  // FICHIERS //
  {
    path: '/fichiers/',
    name: 'fichiers',
    component: () => import('@/views/pages/Fichiers/Fichiers.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/procuration/new',
    name: 'procuration-new',
    component: () => import('@/views/pages/ProcurationNew.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // END FICHIERS //

  // TEXTES //
  {
    path: '/textes/',
    name: 'textes',
    component: () => import('@/views/pages/Textes/Textes.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/textes-manager',
    name: 'textes-manager',
    component: () => import('@/views/pages/Textes/TextesManager.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // MESSAGES //
  {
    path: '/messages/',
    name: 'messages',
    component: () => import('@/views/pages/Messages/Messages.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // END TEXTES //

  // ADMIN //
  {
    path: '/stats/',
    name: 'stats',
    component: () => import('@/views/Administration/Statistiques/Statistiques.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/facturation',
    name: 'facturation',
    component: () => import('@/views/Administration/Facturation/Invoices.vue'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: '/invoice/:id',
    name: 'invoice-single',
    component: () => import('@/views/Administration/Facturation/InvoiceSingle.vue'),

    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: '/new-invoice',
    name: 'invoice-new',
    component: () => import('@/views/Administration/Facturation/InvoiceNew.vue'),

    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Administration/Users/Users.vue'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: '/user-new',
    name: 'user-new',
    component: () => import('@/views/Administration/Users/UserNew.vue'),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Administration/Settings/Settings.vue'),
    meta: {
      // requiresAdmin: true,
    },
  },
  {
    path: '/debug',
    name: 'debug',
    component: () => import('@/views/pages/debug.vue'),
    meta: {
      requiresAdmin: true,
    },
  },

  // END ADMIN //
  // NOT USED //
  {
    path: '/livraisons',
    name: 'livraisons',
    component: () => import('@/views/Livraisons/Livraisons.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/livraison/new',
    name: 'NewLivraison',
    component: () => import('@/views/Livraisons/NewLivraison.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user/account-settings',
    name: 'user-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  // USER AUTH
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/pages/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/pages/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      requiresParamsToken: true,
    },
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/views/pages/VerifyEmail.vue'),
    meta: {
      layout: 'blank',
      requiresParamsToken: true,
    },
  },
  {
    path: '/first-login',
    name: 'first-login',
    component: () => import('@/views/pages/FirstLogin.vue'),
    meta: {
      layout: 'blank',
      requiresParamsToken: true,
    },
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('@/views/pages/Onboarding.vue'),
    meta: {
      layout: 'blank',
    },
  },

  // END USER AUTH

  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-auth',
    name: 'error-auth',
    component: () => import('@/views/ErrorAuth.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    document.getElementById('app').scrollIntoView()
  },
})

router.beforeEach((to, from, next) => {
  // CHECK FOR DELOGGED
  if (to.name !== 'login' && store.state.status === 'error') {
    next('/login')

    return
  }

  // QUICK CHECK FOR TUTEUR DASHBOARD
  if (to.name === 'dashboard') {
    if (store.state.user.status !== 'tuteur' && store.state.user.status !== 'telepro') {
      next('/calendar')
    }
  }

  // POPULATE STORE BEFORE LOAD FOR REQUIRED PAGES
  if (to.matched.some(record => record.meta.requiresStore)) {
    try {
      const requirements = to.meta.requiresStore
      requirements.forEach(req => {
        store.dispatch(req)
      })
      next()

      return
    } catch (err) {
      next(err)
    }
  }

  // AUTHENTICATION SPECIFIC ROUTES
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()

      return
    }
    next('/login')
  } else {
    next()
  }

  // ADMIN ONLY ROUTES
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (store.getters.isLoggedIn && store.getters.isAdmin) {
      next()

      return
    }
    next('/error-auth')
  } else {
    next()
  }

  // TUTEUR ONLY ROUTES
  if (to.matched.some(record => record.meta.requiresTuteur)) {
    if (store.getters.isLoggedIn && store.getters.isTuteur) {
      next()

      return
    }
    next('/error-auth')
  } else {
    next()
  }

  // PARAMS REQURIED ROUTES
  if (to.matched.some(record => record.meta.requiresParamsToken)) {
    if (to.query.token) {
      next()

      return
    }
    next('/error-auth')
  }
})

export default router
