import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})
Vue.use(Vuex)

const defaultState = () => ({
  status: '',
  token: localStorage.getItem('token') || '',
  user: {
    avatar: {
      url: null,
    },
    displayName: 'Default',
  },
  company: {},
  snack: {},
  tasks: [],
  userlist: [],
  students: [],
  preferences: {
    info: true, tasks: false, formation: false, visio: true, documents: false, rappel: true, contact: true, history: true, files: false,
  },
})
export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {
      avatar: {
        url: null,
      },
      displayName: 'Default',
    },
    company: {},
    snack: {},
    tasks: [],
    userlist: [],
    students: [],
    preferences: {
      info: true, tasks: false, formation: false, visio: true, documents: false, rappel: true, contact: true, history: true, files: false,
    },
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, payload) {
      state.status = 'success'
      state.token = payload.token
      state.user = payload.user
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      Object.assign(state, defaultState())
    },
    auth_getInfos(state, payload) {
      state.status = 're-auth'
      state.user = payload.user
    },
    updatingUserPref(state) {
      state.user.preferences = 'updating...'
    },
    user_preferences(state, payload) {
      state.user.preferences = payload
    },
    user_preferences_error(state) {
      state.user.preferences = 'error'
    },
    tasks_loading(state) {
      state.tasks = []
    },
    tasks_error(state) {
      state.tasks = []
    },
    tasks_change(state, payload) {
      const taskIndex = state.tasks.findIndex(task => task.id === payload.task.id)
      if (payload.task.status === 'deleted' || payload.task.status === 'done') {
        state.tasks.splice(taskIndex, 1)
      } else {
        state.tasks.splice(taskIndex, 1, payload.task)
      }
    },
    tasks(state, payload) {
      state.tasks = payload.tasks
    },
    students(state, payload) {
      state.students = payload
    },
    student_error(state) {
      state.students = 'error'
    },
    userlist(state, payload) {
      state.userlist = payload.userlist
    },
    snackMe(state, payload) {
      state.snack = payload
    },
    cleanAll(state, payload) {
      state = payload
    },
    studentUpdate(state, payload) {
      const foundIndex = state.students.findIndex(student => student.id === payload.id)
      if (foundIndex) {
        state.students[foundIndex] = payload
      }
    },
    tutorsUpdate(state, payload) {
      const foundIndex = state.user.tutors.findIndex(student => student.id === payload.id)
      if (foundIndex) {
        state.tutors[foundIndex] = payload
      }
    },
    company(state, payload) {
      state.company = payload
    },
  },
  actions: {
    // AUTH - USER //
    login({ dispatch, commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({ url: `${process.env.VUE_APP_API_URL}/auth/local`, data: user, method: 'POST' })
          .then(resp => {
            const payload = {
              user: resp.data.user,
              token: resp.data.jwt,
            }
            if (payload.user.avatar) {
              payload.user.avatar = `${process.env.VUE_APP_API_URL}${payload.user.avatar.url}`
            } else {
              payload.user.avatar = null
            }
            localStorage.setItem('token', payload.token)
            axios.defaults.headers.common.Authorization = `Bearer ${payload.token}`
            commit('auth_success', payload)

            // dispatch('getTasks')
            dispatch('getStudents')
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    getInfos({ commit }) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({ url: `${process.env.VUE_APP_API_URL}/users/me`, method: 'get' })
          .then(resp => {
            const payload = {
              user: resp.data,
            }
            commit('auth_getInfos', payload)
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common.Authorization
        commit('cleanAll')
        resolve()
        reject()
      })
    },
    updateUserPreferences({ commit }, preferences) {
      return new Promise((resolve, reject) => {
        commit('updatingUserPref')
        axios.post(`${process.env.VUE_APP_API_URL}/users-permissions/updatemypref`,
          { preferences })
          .then(res => {
            if (res.status === 200) {
              const payload = res.data

              commit('user_preferences', payload)
              resolve(res)
            }
          })
          .catch(err => {
            commit('user_preferences_error')
            reject(err)
          })
      })
    },

    // TASKS //
    getTasks({ commit }) {
      commit('tasks_loading')
      axios
        .get(`${process.env.VUE_APP_API_URL}/tasks?status_ne=deleted&status_ne=done`)
        .then(resp => {
          const payload = {
            tasks: resp.data,
          }
          commit('tasks', payload)
        })
        .catch(err => {
          console.log(err)
          commit('tasks_error')
        })
    },
    completeTask({ commit }, task) {
      task.status = 'done'

      return new Promise((resolve, reject) => {
        axios({ url: `${process.env.VUE_APP_API_URL}/tasks/${task.id}`, data: task, method: 'PUT' })
          .then(resp => {
          // Mark a student as rappeled
            if (resp.type === 'rappel' && resp.student) {
              const contacts = resp.student.contacts.push(
                {
                  contacter: this.$store.state.user.displayName,
                  contactedby: 'phone',
                  note: 'Rappelé',
                  contactDate: new Date(),
                  contactResult: 'suivi',
                },
              )
              axios.put(`${process.env.VUE_APP_API_URL}/students/${resp.student.id}`, { rappeled: true, contacts })
            }
            const payload = {
              task: resp.data,
            }
            commit('tasks_change', payload)
            resolve(resp)
          })
          .catch(err => {
            commit('tasks_error')
            reject(err)
          })
      })
    },
    deleteTask({ commit }, task) {
      task.status = 'deleted'

      return new Promise((resolve, reject) => {
        axios({ url: `${process.env.VUE_APP_API_URL}/tasks/${task.id}`, data: task, method: 'PUT' })
          .then(resp => {
            const payload = {
              task: resp.data,
            }
            commit('tasks_change', payload)
            resolve(resp)
          })
          .catch(err => {
            commit('tasks_error')
            reject(err)
          })
      })
    },
    forwardTask({ commit }, task) {
      return new Promise((resolve, reject) => {
        axios({ url: `${process.env.VUE_APP_API_URL}/tasks/${task.id}`, data: task, method: 'PUT' })
          .then(resp => {
            const payload = {
              task: resp.data,
            }
            commit('tasks_change', payload)
            resolve(resp)
          })
          .catch(err => {
            commit('tasks_error')
            reject(err)
          })
      })
    },

    // STUDENTS //
    getStudents({ commit }) {
      return new Promise((resolve, reject) => {
        axios({ url: `${process.env.VUE_APP_API_URL}/students/light`, method: 'get' })
          .then(resp => {
            const payload = resp.data
            commit('students', payload)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    tutorNoteUpdate({ commit }, newNote) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_URL}/students/${newNote.id}`, { tutorNote: newNote.tutorNote })
          .then(resp => {
            const payload = resp.data
            commit('studentUpdate', payload)
            commit('tutorsUpdate', payload)
            resolve(resp)
          })
          .catch(err => {
            // commit('students_error')
            reject(err)
          })
      })
    },

    // MISC //
    getUserlist({ commit }) {
      return new Promise((resolve, reject) => {
        axios({ url: `${process.env.VUE_APP_API_URL}/users-permissions/userlist`, method: 'get' })
          .then(resp => {
            const payload = {
              userlist: resp.data,
            }
            commit('userlist', payload)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    cleanAll({ commit }) {
      const payload = {
        status: '',
        token: localStorage.getItem('token') || '',
        user: {
          avatar: {
            url: null,
          },
          displayName: 'Default',
        },
        snack: {},
        tasks: [],
        userlist: [],
        students: [],
      }
      commit('cleanAll', payload)
    },

  },
  modules: {},
  getters: {
    isLoggedIn: state => !!state.token,
    isAdmin: state => state.user.status === 'admin',
    isTuteur: state => state.user.status === 'admin' || 'tuteur',
    authStatus: state => state.status,
    myTasks(state) { return state.tasks.filter(task => task.users_permissions_user && task.users_permissions_user.id === state.user.id) },
    myTasksTodo(state, getters) { return getters.myTasks.filter(task => task.status === 'todo') },
    tasksTypes(state) {
      return [...new Set(state.tasks.map(task => task.type))]
    },
    getTasksByStudent: state => id => state.tasks.filter(task => task.student && task.student.id === id),
    getTasksByDate: state => date => {
      const dateToFind = date.setHours(0, 0, 0, 0)

      return state.tasks.filter(task => new Date(task.date).setHours(0, 0, 0, 0) === dateToFind)
    },
    userSignature(state) {
      return state.user.emailSignature
    },
    userStatus: state => state.user.status,
    getTutors: state => state.userlist.filter(x => x.status === 'tuteur'),
    myTutors: state => (state.user.status === 'tuteur' ? state.user.tutors : false),
  },
  plugins: [vuexLocal.plugin],
})
