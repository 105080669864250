<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="240"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.png')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            La Formation Pro
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        v-if="$store.getters.isTuteur"
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Calendrier"
        :to="{ name: 'calendar' }"
        :icon="icons.mdiCalendarClock"
      ></nav-menu-link>
      <nav-menu-group
        title="Stagiaires"
        :icon="icons.mdiAccountHeart"
      >
        <nav-menu-link
          title="Liste"
          :to="{ name: 'students' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Nouveau stagiaire"
          :to="{ name: 'student-new' }"
        ></nav-menu-link>
      </nav-menu-group>
      <!--   <nav-menu-link
        title="Messagerie"
        :to="{ name: 'messages' }"
        :icon="icons.mdiEmail"
      ></nav-menu-link> -->
      <nav-menu-link
        title="Formations"
        :to="{ name: 'formations' }"
        :icon="icons.mdiSchoolOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Certifications"
        :to="{ name: 'certifications' }"
        :icon="icons.mdiCertificateOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Avis"
        :to="{ name: 'reviews' }"
        :icon="icons.mdiMessageDraw"
      ></nav-menu-link>
      <nav-menu-group
        title="Tutorat"
        :icon="icons.mdiHumanGreeting"
      >
        <nav-menu-link
          title="Planning"
          :to="{ name: 'tutorat-planning' }"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group
        title="Fichiers"
        :icon="icons.mdiFolder"
      >
        <nav-menu-link
          title="Fichiers"
          :to="{ name: 'fichiers' }"
          :icon="icons.mdiFolder"
        ></nav-menu-link>
        <nav-menu-link
          title="Procurations"
          :to="{ name: 'procuration-new' }"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group
        title="Textes"
        :icon="icons.mdiText"
      >
        <nav-menu-link
          title="Textes type"
          :to="{ name: 'textes' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Gérer les textes"
          :to="{ name: 'textes-manager' }"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group
        v-if="$store.getters.isAdmin"
        title="Admin"
        :icon="icons.mdiCog"
      >
        <nav-menu-link
          title="Paramètres"
          :to="{ name: 'settings' }"
          :icon="icons.mdiCog"
        ></nav-menu-link>
        <nav-menu-group
          title="Facturation"
          :icon="icons.mdiCash100"
        >
          <nav-menu-link
            title="Accueil"
            :to="{ name: 'facturation' }"
          ></nav-menu-link>
          <nav-menu-link
            title="Nouvelle facture"
            :to="{ name: 'invoice-new' }"
          ></nav-menu-link>
        </nav-menu-group>
        <nav-menu-link
          title="Statistiques"
          :to="{ name: 'stats' }"
          :icon="icons.mdiGoogleAnalytics"
        ></nav-menu-link>
        <nav-menu-group
          title="Utilisateurs"
          :icon="icons.mdiAccountMultiple"
        >
          <nav-menu-link
            title="Liste"
            :to="{ name: 'users' }"
          ></nav-menu-link>
          <nav-menu-link
            title="Ajouter utilisateur"
            :to="{ name: 'user-new' }"
          ></nav-menu-link>
        </nav-menu-group>
      </nav-menu-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiCalendarClock,
  mdiAlphaTBoxOutline,
  mdiAccountHeart,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiPhoneDial,
  mdiClipboardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiSchoolOutline,
  mdiCertificateOutline,
  mdiHumanGreeting,
  mdiGoogleAnalytics,
  mdiFolder,
  mdiText,
  mdiCog,
  mdiCash100,
  mdiAccountMultiple,
  mdiEmail,
  mdiMessageDraw,
} from '@mdi/js'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      icons: {
        mdiHomeOutline,
        mdiCalendarClock,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiAccountHeart,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiClipboardOutline,
        mdiPhoneDial,
        mdiSchoolOutline,
        mdiCertificateOutline,
        mdiHumanGreeting,
        mdiGoogleAnalytics,
        mdiFolder,
        mdiText,
        mdiCog,
        mdiCash100,
        mdiAccountMultiple,
        mdiEmail,
        mdiMessageDraw,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
