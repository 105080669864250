<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>
    <v-snackbar
      v-model="snack.value"
      class="text-center"
      :color="snack.color"
      :timeout="5000"
    >
      {{ snack.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snack.value = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-icon
            color="primary"
            @click="isDrawerOpen = !isDrawerOpen"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <ButtonTopNavigationBackVue></ButtonTopNavigationBackVue>
          <AppBarSearchStudentVue></AppBarSearchStudentVue>

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <app-bar-tasks-menu-vue></app-bar-tasks-menu-vue>
          <theme-switcher></theme-switcher>
          <app-bar-notifications-menu></app-bar-notifications-menu>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container">
        <slot></slot>
        <ButtonBackToTopVue></ButtonBackToTopVue>
      </div>
    </v-main>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
      class="px-0"
    >
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; {{ new Date().getFullYear() }} La Formation Pro </span>
          <span class="d-sm-inline d-none">

            <a
              href="#"
              target="_blank"
              class="text--secondary text-decoration-none"
            >Jimbo ©</a>
          </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import {
  mdiMagnify, mdiBellOutline, mdiGithub, mdiMenu,
} from '@mdi/js'
import ButtonBackToTopVue from '@/components/ButtonBackToTop.vue'
import ButtonTopNavigationBackVue from '@/components/ButtonTopNavigationBack.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import AppBarNotificationsMenu from './components/AppBarNotificationsMenu.vue'
import AppBarTasksMenuVue from './components/AppBarTasksMenu.vue'
import AppBarSearchStudentVue from './components/AppBarSearchStudent.vue'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    AppBarNotificationsMenu,
    AppBarTasksMenuVue,
    ButtonBackToTopVue,
    ButtonTopNavigationBackVue,
    AppBarSearchStudentVue,
  },
  data() {
    return {
      isDrawerOpen: null,
      snack: {
        value: false,
        color: 'primary',
        text: 'default',
      },
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiMenu,
      },
    }
  },
  computed: {
    snacky() {
      return this.$store.state.snack
    },
  },
  watch: {
    snacky(newsnack) {
      this.snack = newsnack
    },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
