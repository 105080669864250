<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="600"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        top
        left
        overlap
        :color="$store.getters.myTasksTodo.length > 0 ? 'error' : ''"
        :content="$store.getters.myTasksTodo.length"
        offset-x="12"
        offset-y="12"
        class="ms-6"
      >
        <v-btn
          icon
          small
          v-bind="attrs"
          @click="fetchData"
          v-on="on"
        >
          <v-icon>
            {{ ($store.getters.myTasksTodo.length > 0) ? icons.mdiBell : icons.mdiBellOutline }}
          </v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-list>
      <p class="text-center">
        Mes taĉhes
      </p>
      <!-- Tâches -->
      <div
        v-for="task in $store.getters.myTasksTodo"
        :key="task.id"
        class="listStudentNotif"
      >
        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-avatar>
            <v-icon
              v-if="task.type === 'entree'"
              size="40"
            >
              {{ icons.mdiLocationEnter }}
            </v-icon>
            <v-icon
              v-else-if="task.type === 'sortie'"
              size="40"
            >
              {{ icons.mdiLocationExit }}
            </v-icon>
            <v-icon
              v-else
              size="40"
            >
              {{ icons.mdiChatOutline }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ new Date(task.date) | date('HH:mm') }} -
              {{ niceType(task.type) }}
              <v-list-item-subtitle
                v-if="task.student"
                class="my-2"
              >
                {{ task.student.nom }} {{ task.student.prenom }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ task.note }}
              </v-list-item-subtitle>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon
            v-if="task.student"
            class="me-2"
            @click="goToStudent(task.student.id)"
          >
            <v-icon
              size="35"
              class="hover-i"
            >
              {{ icons.mdiAccountCircleOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-icon
            class="me-2"
            @click="completeTask(task.id)"
          >
            <v-icon
              size="35"
              class="hover-i-green"
            >
              {{ icons.mdiCheckCircleOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-icon
            class="me-2"
            @click="deleteTask(task.id)"
          >
            <v-icon
              size="35"
              class="hover-i-red"
            >
              {{ icons.mdiDeleteCircleOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-dialog
            v-model="forwardDialog"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon
                v-bind="attrs"
                class="me-2"
                v-on="on"
              >
                <v-icon
                  size="35"
                  class="hover-i"
                >
                  {{ icons.mdiArrowUpThinCircleOutline }}
                </v-icon>
              </v-list-item-icon>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Transférer la tâche à :
              </v-card-title>
              <v-card-text>
                <v-select
                  v-model="forwardTo"
                  :items="$store.state.userlist"
                  item-text="displayName"
                  item-value="id"
                  label="Attribuer à"
                  outline
                ></v-select>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="forwardTask(task.id)"
                >
                  Valider
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiLocationEnter,
  mdiLocationExit,
  mdiAccountCircleOutline,
  mdiAccountOutline,
  mdiArrowUpThinCircleOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiPhoneOutline,
  mdiBellOutline,
  mdiDeleteCircleOutline,
  mdiCheckCircleOutline,
  mdiBell,
} from '@mdi/js'

export default {
  data() {
    return {
      today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 19),
      dailyStuds: [],
      forwardDialog: false,
      forwardTo: null,
      icons: {
        mdiLocationEnter,
        mdiLocationExit,
        mdiAccountCircleOutline,
        mdiArrowUpThinCircleOutline,
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiBellOutline,
        mdiPhoneOutline,
        mdiDeleteCircleOutline,
        mdiCheckCircleOutline,
        mdiBell,
      },
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
    //  this.$store.dispatch('getTasks')
      this.$store.dispatch('getUserlist')
    },
    goToStudent(e) {
      this.$router.push(`/student/view/${e}`)
      this.$router.go()
    },
    completeTask(t) {
      this.$store.dispatch('completeTask', { id: t })
    },
    deleteTask(t) {
      this.$store.dispatch('deleteTask', { id: t })
    },
    forwardTask(t) {
      this.$store.dispatch('forwardTask', { id: t, users_permissions_user: this.forwardTo })
      this.forwardDialog = false
    },
    niceType(a) {
      switch (a) {
        case 'entree':
          return 'Entrée en formation'
        case 'sortie':
          return 'Sortie de formation'
        default:
          return 'Rappel'
      }
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    height: auto !important;
    min-height: 2.5rem !important;
  }
}
.listStudentNotif a {
  text-decoration: none;
}
.hover-i:hover{
  cursor: pointer;
  color: #9155fd;
}
.hover-i-red:hover {
  cursor: pointer;
  color: #ff4c51;
}
.hover-i-green:hover {
  cursor: pointer;
  color: #56ca00;
}
</style>
