<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        class="ml-5"
        color="primary"
        v-on="on"
      >
        {{ icons.mdiMagnify }}
      </v-icon>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <StudentSearchAutocompleteVue
          @studentSearchSelected="studentSearchSelected"
        />
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="dialog.value = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mdiMagnify } from '@mdi/js'
import StudentSearchAutocompleteVue from '../../components/StudentSearchAutocomplete.vue'

export default {
  components: {
    StudentSearchAutocompleteVue,
  },
  data() {
    return {
      selectedResult: '',
      search: '',
      noresults: false,
      loading: false,
      results: [],
      icons: {
        mdiMagnify,
      },
    }
  },
  methods: {
    studentSearchSelected(val) {
      this.$router.push({ name: 'student-single', params: { id: val.id } })
    },
  },
}
</script>

<style>

</style>
